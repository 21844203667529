<template>
  <div class="main">
    <div class="box">
      <h1>{{ $t("legal.title")}}</h1>
      <router-link :to="{name : 'Home'}" class="link">www.safeorthopaedics.com</router-link>
      <p v-html="$t('legal.text1')"></p>
      <p v-html="$t('legal.text2')"></p>
      <p v-html="$t('legal.text3')"></p>
      <p v-html="$t('legal.text4')"></p>
      <p v-html="$t('legal.text5')"></p>
      <p v-html="$t('legal.text6')"></p>
      <p v-html="$t('legal.text7')"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: "LegalNotice",
}
</script>

<style scoped lang="scss">
  h1 {
    color:#fff;
    text-align: center;
    text-transform: uppercase;
    font-size:35px;
    font-weight:400;
    margin-bottom:40px;
    margin-top:80px;
  }

  .link {
    color:$mainColor;
    text-align: center;
    margin:0 auto 40px auto;
    font-size:15px;
    text-decoration: none;
    transition:0.5s;
    font-weight:300;
    display: block;
  }

  .link:hover {
    color:#fff;
  }


</style>